/**
 * Used by 'gatsby-plugin-layout' plugin,
 * Automatically wrap your pages with the Layout component
 * Layout component is rerendered on every route change.
 *
 * Consider wrapPageElement or wrapRootElement on gatsby-browser.js
 * for persistent state
 */

import React, { useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';

import 'react-modal-video/css/modal-video.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Global from '../style/global';

import { layoutTypes } from '../types/propTypes';
import AppNavbar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = ({ children, location }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navRef = useRef();
  navRef.current = isScrolled;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 70;
      if (navRef.current !== show) {
        setIsScrolled(show);
      }
    };
    document.addEventListener(
      'scroll',
      throttle(() => {
        handleScroll();
      }, 100)
    );
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Global />
      <AppNavbar
        scrolled={isScrolled}
        darkText={
          location.pathname !== '/' && location.pathname !== '/privacy-policy'
        }
      />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = layoutTypes;

export default Layout;
