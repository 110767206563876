import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { COLORS, FONTS } from '../constants/style';

const contactInfoQuery = graphql`
  {
    contactInfo: datoCmsContactInfo {
      campusAddresses {
        address
      }
      emailAddress
      facebookLink
      id
      phoneNumber
      youtubeLink
    }
  }
`;

const FooterContainer = styled.footer`
  font-size: 1.05rem;
  min-height: 500px;
  background: ${COLORS.bgGray};
  padding-top: 80px;
  padding-bottom: 80px;
  font-family: ${FONTS.secondary};

  .footer-links {
    margin-top: 0.7em;
    padding-left: 0.5em;
    a {
      margin-bottom: 0.4em;
      font-family: ${FONTS.secondary};
      display: block;
      text-transform: none;
      color: ${COLORS.textWhite};
    }
  }

  .socials {
    img {
      height: 35px;
      margin-right: 15px;

      &:hover {
        filter: grayscale(1);
      }
    }
  }
`;

const Heading = styled.div`
  color: ${COLORS.darkGold};
  text-transform: uppercase;
  margin-bottom: 0.7em;
  font-size: 1.1em;
  font-weight: 900;
  letter-spacing: 1.2px;
`;

const Content = styled.div`
  letter-spacing: 0.8px;
  font-family: ${FONTS.secondary} !important;
  color: ${COLORS.textWhite};
  margin-bottom: 2em;

  p {
    margin: 0;
  }
`;

const Footer = () => {
  const data = useStaticQuery(contactInfoQuery);
  const {
    campusAddresses,
    emailAddress,
    phoneNumber,
    facebookLink,
    youtubeLink,
  } = data.contactInfo;

  return (
    <FooterContainer>
      <Container>
        <Row noGutters>
          <Col xs={{ size: 6, order: 1 }} md="4">
            <img width="130" src={'/img/logo-gold.png'} alt="Navbar Logo" />
            <div className="footer-links">
              <Link to="/about">About</Link>
              <Link to="/classes">Classes</Link>
              <Link to="/content">Content</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
          </Col>
          <Col xs={{ size: 12, order: 2 }} md="4" className="mt-5 mt-md-0">
            <Heading>Address</Heading>
            {campusAddresses.map(({ address }) => (
              <Content
                key={address}
                dangerouslySetInnerHTML={{ __html: address }}
              />
            ))}
          </Col>
          <Col xs={{ size: 6, order: 1 }} md="4">
            <Heading>Phone</Heading>
            <Content>{phoneNumber}</Content>
            <Heading>Email</Heading>
            <Content>{emailAddress}</Content>
            <div className="socials">
              <a rel="noopener noreferrer" target="_blank" href={facebookLink}>
                <img src="/img/socials/facebook.svg" alt="facebook" />
              </a>
              <a rel="noopener noreferrer" target="_blank" href={youtubeLink}>
                <img src="/img/socials/youtube.svg" alt="youtube" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
