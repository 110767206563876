import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const timeout = 0;

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;
    const isHome = location.pathname === '/';

    const RoutesContainer = posed.div({
      enter: {
        opacity: 1,
        delay: 200,
        beforeChildren: true,
        staggerChildren: 50,
      },
      exit: { opacity: 0, taggerChildren: 20, staggerDirection: -1 },
    });

    return (
      <PoseGroup>
        <RoutesContainer key={location.pathname}>{children}</RoutesContainer>
      </PoseGroup>
    );
  }
}

export default Transition;
