import 'typeface-inter';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../constants/style';

const Global = createGlobalStyle`
  html, body {
    font-family: 'Nunito', sans-serif;
    color: ${COLORS.textMain};
    font-size: 17px;
    /* letter-spacing: .5px; */
    user-select: none !important;
  }

  h1,h2,h3,h4,h5,h6, blockquote {
    font-family: 'Crimson Text', serif;
  }

  blockquote {
    font-style: italic;
    text-align: unset;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .text-main {
    color: ${COLORS.textMain} !important;
  }

  .text-gold {
    color: ${COLORS.darkGold} !important;
  }

  .bg-gold {
    background: ${COLORS.brightGold} !important;
  }

  .letter-spaced-1 {
    letter-spacing: 1px;
  }

  .styless-link {
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;

    &:hover {
      color: ${COLORS.darkGold};
    }
  }
`;

export default Global;
