const COLORS = {
  textWhite: '#fff',
  textOffWhite: '#cfcfcf',

  textMain: '#333333',
  textGray: '#4a4a4a',
  textLightGray: '#969696',

  brightGold: '#f5cf78',
  darkGold: '#DEBC70',

  lightGray: '#F3F3F3',
  darkGray: '#424650',

  bgGray: '#424650',
};

const FONTS = {
  primary: "'Nunito', sans-serif;",
  secondary: "'Crimson Text', serif;",
};

export { COLORS, FONTS };
