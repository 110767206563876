import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { COLORS } from '../constants/style';

const FilteredNav = ({ darkText, scrolled, isOpen, ...rest }) => (
  <Navbar {...rest} />
);

const StyledNav = styled(FilteredNav)`
  z-index: 9;
  padding: ${props => (props.scrolled ? '5px' : '15px')} 30px;
  transition: 300ms;

  .navbar-brand img {
    transition: 300ms;
  }

  ${props =>
    props.scrolled &&
    `
    background: #282828;
    opacity: .95;
  `}

  ul.navbar-nav {
    ${props =>
      props.isOpen &&
      `
      background: ${COLORS.textMain};
    `}
  }

  li {
    padding: 8px 15px;

    &:hover {
      background: ${COLORS.darkGold};
      a {
        color: ${COLORS.textMain} !important;
      }
    }
  }

  a {
    cursor: pointer;
    font-size: 0.85em;
    font-weight: 100;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${props =>
      props.darkText && !props.scrolled && !props.isOpen
        ? COLORS.textMain
        : COLORS.textWhite} !important;
  }
`;

const AppNavbar = ({ scrolled, darkText = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onNavClick = e => {
    e.preventDefault();
    setIsOpen(false);
    navigate(e.target.name);
  };

  return (
    <StyledNav
      fixed="top"
      darkText={darkText}
      scrolled={scrolled}
      isOpen={isOpen}
      expand="md"
      {...(scrolled ? { dark: true } : { light: true })}
    >
      <NavbarBrand tag={Link} to="/">
        {scrolled ? (
          <img width="130" src={'/img/logo-gold.png'} alt="Navbar Logo" />
        ) : (
          <img
            width="150"
            src={
              darkText
                ? '/img/logo-gray-motto.png'
                : '/img/logo-white-motto.png'
            }
            alt="Navbar Logo"
          />
        )}
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink onClick={onNavClick} name="/">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={onNavClick} name="/about">
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={onNavClick} name="/classes">
              Classes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={onNavClick} name="/content">
              Content
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink onClick={onNavClick} name="/about">
              Videos
            </NavLink>
          </NavItem> */}
          {/* <NavItem>
            <NavLink onClick={onNavClick} name="/about">
              Classes
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink onClick={onNavClick} name="/contact">
              Contact Us
            </NavLink>
          </NavItem>
          <NavItem>
            <a
              style={{
                fontWeight: 600,
              }}
              className="nav-link"
              href="https://edcellent.pike13.com"
              target="_blank"
              rel="noreferrer"
            >
              Member Login
            </a>
          </NavItem>
          {/* <NavItem>
            <NavLink onClick={onNavClick} name="/contact">
              Check In
            </NavLink>
          </NavItem> */}
        </Nav>
      </Collapse>
    </StyledNav>
  );
};

export default AppNavbar;
